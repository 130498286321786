module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-segment-js/gatsby-browser.js'),
      options: {"plugins":[],"prodKey":"YspTZAK8HHvmcQttGBQ14DWq1O8bcpjW","devKey":"5lIqtqhw7jIAhrtUyx17Z4wepzZa49Cl","trackPage":false,"trackPageDelay":50,"host":"https://evs.analytics.liveouter.com","delayLoad":false,"delayLoadTime":1000,"manualLoad":false},
    },{
      plugin: require('../node_modules/@sentry/gatsby/gatsby-browser.js'),
      options: {"plugins":[],"dsn":"https://0051a6d8bd4e4e29b5cc49460ed2ff24@o239998.ingest.sentry.io/5738262","sampleRate":0.7},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
